import { Injectable } from "@angular/core";
import { SERVER_URL } from "../../config";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { Page } from "models/page";
import { PagedData } from "models/page-data";
import { AuthenticationService } from "../authentication/authentication.service";

@Injectable({
  providedIn: "root",
})
export class TributeService {
  authorization: any;
  constructor(
    private http: HttpClient,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.authorization = this.authenticationService.getAuthorizationToken();
  }

  list(page, id, type, name) {
    let httpParams: HttpParams = new HttpParams()
      .append("start", (page.size * page.pageNumber).toString())
      .append("length", page.size)
      .append("type", type)
      .append("name", name);

    return this.http
      .get(SERVER_URL + "/morada/deceased/" + id + "/memory/list", {
        params: httpParams,
      })
      .pipe(
        map((data) => {
          return this.getPagedData(page, data);
        })
      );
  }

  delete(id) {
    return this.http
      .delete(SERVER_URL + "/morada/deceased/memory/" + id, {
        headers: this.authorization,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  changeDisplayOption(memory) {
    return this.http
      .post(
        SERVER_URL + "/morada/deceased/memory/changeDisplayOption",
        memory,
        { headers: this.authorization }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  deactivate(memory) {
    return this.http
      .post(
        SERVER_URL + "/morada/deceased/memory/deactivate",
        memory,
        { headers: this.authorization }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  private getPagedData(page: Page, data: any): PagedData<any> {
    const pagedData = new PagedData<any>();
    page.totalElements = data.recordsTotal;
    page.totalPages = page.totalElements / page.size;
    if (data.data.list !== undefined) {
      pagedData.data = data.data.list;
    }
    pagedData.page = page;
    return pagedData;
  }
}
